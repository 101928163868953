@import url('https://fonts.googleapis.com/css2?family=Bad+Script&display=swap');

body {
    margin: 0;
    padding: 0;
    background-color: antiquewhite;
}
h1 {
    font-family: 'Bad Script', cursive;
    font-weight: 700;
    color: #0e0d0c;
}